<template>
    <div class="box">
        <div class="top">
            <div>
                <span class="l">患者姓名：</span>
                <span class="r" style="margin-right:50px">{{patientName}}</span>
                <span class="l">套餐：</span>
                <span class="r">{{patientPackage}}</span>
            </div>
            <div>
                <span class="l">年龄：</span>
                <span class="r">{{age}}</span>
            </div>
            <!-- <div>
                <span class="l">治疗建议：</span>
                <span class="r">{{patientAdvise}}</span>
            </div> -->
            <div>
                <span class="l">主诉：</span>
                <span class="r">{{patientComplaint}}</span>
            </div>
        </div>
        <div class="bottom" v-if="srcList.length > 0">
            <div class="one">
                <p class="title">面像照(4)</p>
                <div>
                    <div>
                        <el-image :src="srcList[0]" :preview-src-list="srcList">
                            <div slot="error" class="image-slot">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </el-image>
                    </div>
                    <div>
                        <el-image :src="srcList[1]" :preview-src-list="srcList">
                            <div slot="error" class="image-slot">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </el-image>
                    </div>
                    <div>
                        <el-image :src="srcList[2]" :preview-src-list="srcList">
                            <div slot="error" class="image-slot">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </el-image>
                    </div>
                    <div>
                        <el-image :src="srcList[98]" :preview-src-list="srcList">
                            <div slot="error" class="image-slot">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </el-image>
                    </div>
                </div>
            </div>
            <div class="two">
                <p class="title">口内照(6)</p>
                <div>
                    <div>
                        <el-image :src="srcList[3]" :preview-src-list="srcList">
                            <div slot="error" class="image-slot">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </el-image>
                    </div>
                    <div>
                        <el-image :src="srcList[4]" :preview-src-list="srcList">
                            <div slot="error" class="image-slot">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </el-image>
                    </div>
                    <div>
                        <el-image :src="srcList[5]" :preview-src-list="srcList">
                            <div slot="error" class="image-slot">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </el-image>
                    </div>
                    <div>
                        <el-image :src="srcList[6]" :preview-src-list="srcList">
                            <div slot="error" class="image-slot">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </el-image>
                    </div>
                    <div>
                        <el-image :src="srcList[7]" :preview-src-list="srcList">
                            <div slot="error" class="image-slot">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </el-image>
                    </div>
                    <div>
                        <el-image :src="srcList[97]" :preview-src-list="srcList">
                            <div slot="error" class="image-slot">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </el-image>
                    </div>
                </div>
            </div>
            <div class="three">
                <p class="title">X光片(3)</p>
                <div>
                    <div>
                        <el-image :src="srcList[8]" :preview-src-list="srcList">
                            <div slot="error" class="image-slot">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </el-image>
                    </div>
                    <div>
                        <el-image :src="srcList[9]" :preview-src-list="srcList">
                            <div slot="error" class="image-slot">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </el-image>
                    </div>
                    <div>
                        <el-image :src="srcList[10]" :preview-src-list="srcList">
                            <div slot="error" class="image-slot">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </el-image>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import baseUrl from '@/main.js'
    export default {
        name: "idea",
        data() {
            return {
                // 患者名字
                patientName: '',
                // 年龄
                age: '',
                // 套餐
                patientPackage: '',
                // 建议
                patientAdvise: '',
                //主诉
                patientComplaint: '',
                srcList: []

            };
        },
        props: [
            'caseId'
        ],
        created() {
            this.init();
        },
        methods: {
            init() {
                //个人信息,
                this.$http({
                    url: '/cooperation/lmPatient',
                    method: 'POST',
                    data: {
                        caseId: this.caseId,
                    }
                }).then(({
                    data
                }) => {
                    if (data.code == 200) {
                        let datas = JSON.parse(data.data);
                        this.patientName = datas.patientName
                        this.age = datas.age
                        this.patientPackage = datas.patientPackage;
                        this.patientAdvise = datas.patientAdvise;
                        this.patientComplaint = datas.patientComplaint;
                    }
                })
                this.srcList = [];
                //图片,
                this.$http({
                    url: 'cooperation/getPhotos',
                    method: 'POST',
                    data: {
                        caseId: this.caseId,
                    }
                }).then(({
                    data
                }) => {
                    if (data.code == 200) {
                        let datas = JSON.parse(data.data);
                        let list = datas.filter((n) => {
                            return n.fileNumber
                        }).sort((x, y) => {
                            return x.fileNumber - y.fileNumber
                        })

                        list.map((e, i) => {
                            this.srcList[Number(e.fileNumber) - 1] = e.path;
                            this.$set(this.srcList, Number(e.fileNumber) - 1, e.path)
                        })
      
      
                    }
                })
            }
        }
    }
</script>

<style scoped lang="less">
    .box {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 20px;
        overflow-y: auto;
        box-sizing: border-box;

        .top {
            border-bottom: 1px solid #ccc;

            &>div {
                margin-bottom: 20px;

                .l {
                    font-size: 14px;
                    color: #666666;
                }

                .r {
                    color: #333333;
                }
            }
        }

        .bottom {
            padding: 12px 0;

            &>div {
                margin-bottom: 28px;

                .title {
                    margin-bottom: 12px;
                    font-size: 18px;
                    color: #1d69af;
                }

                &>div {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    margin: 20px 0;

                    &>div {
                        width: 120px;

                        height: 96px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-right: 33px;
                        margin-bottom: 16px;
                        background-image: url(../../../assets/切图.png);
                        background-repeat: no-repeat;
                        background: #DFE0E2;

                        &:nth-child(3n) {
                            margin-right: 0;
                        }

                        .el-image {
                            max-width: 100%;
                            max-height: 100%;

                            i {
                                color: #A1A2A4;
                                font-size: 36px;
                            }
                        }
                    }
                }
            }
            .one {
                &>div {
                    &>div {
                        width: 96px;

                        &:nth-child(1n) {
                            margin-right: 14px;
                        }
                        &:nth-child(4n) {
                            margin-right: 0;
                        }
                    }
                }

            }
        }
    }
</style>