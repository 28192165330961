<template>
    <div class="box">
        <ul class="mains" ref="chat">
            <li v-for="(item,i) in chatList" :key="i" :class="{service:item.sender == 2}">
                <div class="head">
                    <div class="left">
                        <template v-if="item.sender == 2">
                            <div>LM</div>
                            <span>罗慕产品服务中心</span>
                        </template>
                        <template v-else>
                            <div>罗</div>
                            <span>{{doctorName}}</span>
                        </template>
                    </div>
                    <div class="right">
                        <span>{{dateToStr(item.sendTime)}}</span>
                    </div>
                </div>
                <div class="body">
                    <span>{{item.msg}}</span>
                </div>
            </li>
            <div id="msg_end" style="height:0px; overflow:hidden"></div>
        </ul>
        <div class="chatFrame">
            <el-input resize="none" type="textarea" :rows="2" @keyup.enter.native="sendChat" placeholder="请输入方案修改意见 "
                v-model="textarea">
            </el-input>
            <el-button @click="sendChat">发送</el-button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "idea",
        data() {
            return {
                chatList: [],
                textarea: '',
                doctorName: '',
                outerNo: '',
                circulation:null,
            };
        },
        props: [
            'planId', 'caseId'
        ],
           mounted() { this.$store.commit('changeCopy',true)
            this.init();
        },
        beforeDestroy(){
            clearInterval(this.circulation)
        },
        methods: {
            async init() {
                await this.doctorByCaseId();
                await this.chatMsgs();
                this.$nextTick(() => {
                    try {
                        this.$refs.chat.scrollTop = this.$refs.chat.scrollHeight;
                    } catch (err) {

                    }
                })
                this.circulation = setInterval(() => {
                    this.chatMsgs();
                }, 20000)
            },
            // 发送消息 sendType  医生发送为1 客服发送为2
            sendChat() {
                this.$http({
                    url: '../cooperation/lmCase/sendChatMsg',
                    method: 'POST',
                    data: {
                        outerNo: this.outerNo,
                        sendType: 1,
                        msg: this.textarea,
                        planNo: this.planId || 1,
                    }
                }).then(({
                    data
                }) => {
                    this.textarea = '';
                    this.chatMsgs();
                })
            },
            //接收消息
            chatMsgs() {
                return this.$http({
                    url: '../cooperation/lmCase/chatMsgs',
                    method: 'POST',
                    data: {
                        outerNo: this.outerNo
                    }
                }).then(({
                    data
                }) => {
                    if (data.code == 200) {
                        this.chatList = JSON.parse(data.data).reverse();
                        console.log(this.chatList)
                        this.$nextTick(() => {
                            try {
                                this.$refs.chat.scrollTop = this.$refs.chat.scrollHeight;
                            } catch (err) {

                            }
                        })
                    }
                })
            },
            // 根据病例编号获取医生和病例信息
            doctorByCaseId() {
                return this.$http({
                    url: '/caseInfo/doctorByCaseId',
                    method: 'POST',
                    data: {
                        caseId: this.caseId,
                    }
                }).then(({
                    data
                }) => {
                    if (data.code == 200) {
                        let msg = JSON.parse(data.data);
                        this.doctorName = msg.doctorName;
                        this.outerNo = msg.outerNo;
                    }
                })
            },
            // 日期转换
            dateToStr(data) {
                data = new Date(data);
                let y = data.getFullYear();
                let m = data.getMonth() + 1;
                m = m < 10 ? '0' + m : m;
                let d = data.getDate();
                d = d < 10 ? '0' + d : d;
                let H = data.getHours();
                H = H < 10 ? '0' + H : H;
                let minute = data.getMinutes();
                minute = minute < 10 ? '0' + minute : minute;
                return y.toString() + '.' + m + '.' + d + ' ' + H + ':' + minute;
            }
        }
    }
</script>

<style scoped lang="less">
    .box {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        .mains {
            height: calc(100% - 110px);
            width: 100%;
            overflow: auto;

            li {
                width: 480px;
                margin: 0 auto;
                padding: 16px 32px;
                margin-bottom: 24px;
                border-radius: 8px;
                box-sizing: border-box;

                .head {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 12px;

                    .left {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        div {
                            height: 46px;
                            line-height: 46px;
                            width: 46px;
                            text-align: center;
                            background: #4d90cd;
                            border-radius: 50%;
                            color: #fff;
                            margin-right: 10px;
                        }

                        span {
                            font-size: 14px;
                            color: #666;
                        }
                    }

                    .right {
                        font-size: 14px;
                        color: #666;
                        display: flex;
                        align-items: center;
                    }

                    .body {
                        font-size: 14px;
                        color: #666;
                    }
                }
            }

            .service {
                background: #eeeff2;
            }
        }

        .chatFrame {
            box-sizing: border-box;
            height: 110px;
            width: 100%;
            position: relative;
            border-top: 1px solid #f2f2f5;

            /deep/.el-textarea {
                width: 100%;
                height: 50%;

                textarea {
                    width: 100%;
                    height: 100%;
                    border: none;
                }
            }

            /deep/.el-button {
                width: 88px;
                height: 26px;
                background: #1d69af;
                padding: 0;
                position: absolute;
                right: 10px;
                bottom: 25px;

                span {
                    display: block;
                    width: 100%;
                    height: 26px;
                    line-height: 26px;
                    color: #fff;
                }
            }
        }
    }
</style>