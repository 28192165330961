<template>
    <div class="content">
        <div class="content-detail" >

            <iframe class="pdf" :src="pdfUrl" type="" width="100%" height="100%">
            </iframe>



        </div>
    </div>
</template>
<script>
    export default {
        props: [
            'pdfUrl'
        ],
        components: {

        },
        data() {
            return {
                dialogFormVisible: false,
            }
        },

        created() {

        },
        methods: {

        }
    }
</script>
<style lang="less" scoped>
    /*滚动条 start*/
    ::-webkit-scrollbar {
        width: 20px;
        height: 100px;
        background-color: #F5F5F5;
        display: none;
    }

    /*定义滚动条轨道 内阴影+圆角*/
    ::-webkit-scrollbar-track {
        // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);  
        border: 1px solid #dcdcdc;
        background: #fff;
    }

    /*定义滑块 内阴影+圆角*/
    ::-webkit-scrollbar-thumb {
        border-radius: 20px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
        background-color: #dfe0e2;
    }



    .content {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 50%;
        transform: translate(-50%);
        border: 1px solid #e2e2e2;
        overflow: auto;

        .content-detail {
            width: 100%;
            height: 100%;

            .el-image {
                width: 100%;
                height: 100%;

                .el-image__error {
                    height: 100%
                }
            }
        }
    }

    .download {
        width: 90%;
        position: absolute;
        bottom: 15px;
        left: 50%;
        transform: translate(-50%);

        p {
            font-size: 16px;
            line-height: 36px;
            position: absolute;
            left: 50%;
            transform: translate(-50%);
        }

        .el-button {
            font-size: 16px;
            float: right;
        }
    }

    .operator {
        padding-bottom: 30px;
    }
</style>